import React, { useEffect } from "react";

const QRLink = () => {
  useEffect(() => {
    window.location.href = "https://apps.apple.com/in/app/figrun/id6504271752";
  });

  return <div></div>;
};

export default QRLink;
