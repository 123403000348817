import React, { Fragment } from "react";
import { Box, Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
// import { Link } from "react-router-dom";
import { Link as ScrollLink, Element, Link } from "react-scroll";

const TermsAndConditions = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Terms and conditions | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <Container maxWidth="lg">
        <Box sx={{ py: 5 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Typography variant="h1" color="#000000">
              Terms and conditions
            </Typography>
            <Typography variant="body" color="#000000">
              Last Modified: 1 st July 2024
            </Typography>
          </Box>
          <Typography variant="h3" mt={4}>
            Table of Content
          </Typography>
          <Box mt={2} mb={2}>
            {[
              { id: "terms", text: "1. Terms" },
              { id: "privacy", text: "2. Privacy" },
              { id: "use-of-services", text: "3. Use of the Services" },
              { id: "limited-license", text: "4. Limited License to Use" },
              { id: "content-rights", text: "5. Content and Content Rights" },
              {
                id: "conduct",
                text: "6. Conduct, General Prohibitions, and Cofield’s Enforcement Rights",
              },
              { id: "events", text: "7. Participation in Events" },
              {
                id: "promotions",
                text: "8. Events, Promotions, Contests, Surveys",
              },
              {
                id: "third-party",
                text: "9. Third Party Websites or Resources",
              },
              { id: "warranties", text: "10. Disclaimer of Warranties" },
              { id: "liability", text: "11. Limitation of Liability" },
              { id: "dispute", text: "12. Dispute Resolution" },
              { id: "general", text: "13. General" },
              {
                id: "korea",
                text: "14. Terms Specific to Residents of the Republic of Korea",
              },
              { id: "eea", text: "15. Terms Specific to Residents of the EEA" },
              {
                id: "germany",
                text: "16. Terms Specific to Residents of Germany",
              },
            ].map((item) => (
              <Box mb={1} key={item.id}>
                <ScrollLink
                  to={item.id}
                  smooth={true}
                  duration={500}
                  offset={-70}
                  style={{
                    textAlign: "justify",
                    color: "#000000",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {item.text}
                </ScrollLink>
              </Box>
            ))}
          </Box>
          <Element mt={4} name="terms">
            <Typography variant="h3" mb={1}>
              1. Terms
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Welcome to Cofield. We publish, operate and manage real-world
                mobile experiences, including mobile game applications (" Apps
                "). Namely “Figrun”, “Figrun.com” . Please read these Cofield
                Terms of Service and any applicable App guidelines (the
                “Guidelines“ and, collectively, “the Terms “), because the Terms
                govern your use of such Apps. The Terms also govern your
                interaction with any websites we own or operate (“ Sites “),
                purchase of any Cofield merchandise, participation in Cofield
                live events or promotions (“ Events “), and more generally your
                use of any Cofield products or services (together with Apps, the
                “ Services “).
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Terms and conditions ("Website Terms") on which we, Cofield Pty
                Ltd (ACN 673 047 473 ) ("we", "us" or "Cofiled"), provide our
                services through our website www.figrun.com and any cofiled
                owned and operated mobile application through which you access
                our website or services (together, "Website"). Please read these
                Terms carefully before ordering any products through the Website
                or Apps, as your use of the Website or Apps and purchase of any
                products offered on the Website is subject to these Website
                Terms. By ordering products via the Website (whether now or in
                the future) or continuing to use the Website, you agree to be
                bound by these Website Terms.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                We reserve the right to change these Website Terms from time to
                time by changing them on this page. We advise you to print a
                copy of these Website Terms for future reference. These Website
                Terms are only in the English language.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                If you live in Australia, these Terms are entered into between
                you and Cofield Pty Ltd, Level 3, 14 Martin Place, Sydney
                Australia, ABN 99 673 047 473. If you live in any other country,
                these Terms are entered into between you and Cofield Pty Ltd, a
                company registered to do business under the laws of New South
                Wales, Australia. Cofield Pty Ltd. collectively referred to as
                "Cofield" or “we” in these Terms.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                By using the Services we provide, you are agreeing to these
                Terms. If you don’t agree to these Terms, you may not use the
                Services. Cofield may modify these Terms at any time, and if we
                do, we will notify you by posting the modified Terms on the Site
                or in the App. It’s important that you review any modified Terms
                before you continue using the Services. If you continue to use
                the Services, you are bound by the modified Terms. If you don’t
                agree to be bound by the modified Terms, then you may not use
                the Services.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                If you breach these Terms we may take action against you,
                including but not limited to terminating your account. You
                acknowledge that Cofield has no obligation to, and will not,
                reimburse or refund you for Services lost due to involuntary
                suspension or termination of your account.
              </Typography>
            </Box>
          </Element>
          <Element mt={4} name="privacy">
            <Typography variant="h3" mb={1}>
              2. Privacy
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Our Services are designed to enable you to interact in shared
                game worlds blended with information from the real world. To
                provide the Services, we need information about you, and we only
                use your information where we have a legal basis to do so.
                Please refer to our Privacy Policy to help you understand what
                information we collect, how we use it and what choices you have
                when you use our Services.
              </Typography>
            </Box>
          </Element>
          <Element mt={4} name="use-of-services">
            <Typography variant="h3" mb={1}>
              3. Use of the Services
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                3.1 Cheating
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Cofield prohibits cheating, and we constantly take steps to
                improve our anti-cheat measures. Cheating includes any action
                that attempts to or actually alters or interferes with the
                normal behavior or rules of a Service. Cheating includes, but is
                not limited to, any of the following behavior, on your own
                behalf or on behalf of others:
              </Typography>
              <ul
                style={{
                  marginTop: "10px",
                  paddingLeft: "20px",
                  marginBottom: "20px",
                }}
              >
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Accessing Services in an unauthorized manner (including
                    using modified or unofficial third party software);
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Playing with multiple accounts for the same Service;
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Sharing accounts;
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Using any techniques to alter or falsify a device’s location
                    (for example through GPS spoofing); and/or
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Selling or trading accounts.
                  </Typography>
                </li>
              </ul>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Apps may not work on devices that Cofield detects or reasonably
                suspects to be cheating, and Cofield will not provide support to
                players who attempt to cheat. You agree that Cofield may employ
                any lawful mechanisms to detect and respond to cheating, fraud,
                and other behaviour prohibited under these Terms, including
                checking your device for the existence of exploits or hacking
                and/or unauthorized software. Please see the Guidelines and our
                Privacy Policy for more information.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                3.2 Safe and Appropriate Use
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                While you are using our Services, please be aware of your
                surroundings, and play and communicate safely. You agree that
                your use of the Services is at your own risk, and that you will
                not use the Services to violate any applicable law, regulation,
                Event policies, or instructions as outlined in these Terms, and
                you will not encourage or enable any other individual to do so.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Further, you agree that in conjunction with your use of the
                Services you will not make available any unlawful,
                inappropriate, or commercial Content (defined below). You agree
                that you will not submit inaccurate, misleading, or
                inappropriate Content, including data submissions, edits, or
                removal requests.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Cofield does not intend Apps to be medical or health devices or
                provide medical or health advice.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                3.3 Your Interactions with Other People
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                You agree that in conjunction with your use of the Services, you
                will maintain safe and appropriate contact with other players
                and other people in the real world. You will not harass threaten
                or otherwise violate the legal rights of others. You will not
                trespass, or in any manner attempt to gain or gain access to any
                property or location where you do not have a right or permission
                to be, and will not otherwise engage in any activity that may
                result in injury, death, property damage, nuisance, or liability
                of any kind. If you have a dispute with any third party relating
                to your use of Services, you release Cofield (and our officers,
                directors, agents, subsidiaries, joint ventures, and employees)
                from all claims, demands, and damages (actual and consequential)
                of every kind and nature, known and unknown, suspected and
                unsuspected, disclosed and undisclosed, arising out of or in any
                way connected with such disputes.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                3.4 Eligibility and Account Registration
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                If you want to use certain Services, you will have to create an
                account with us (an " Account "), and you will also need access
                to a supported mobile phone and an Internet connection. We do
                not support rooted or jailbroken devices.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                You can create an Account using (a) your pre-existing Google
                account; (b) your pre-existing apple account, or (c) such other
                third-party accounts that we support, as selected by you on the
                App account creation screen.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                You agree that you won’t disclose your Account password to
                anyone and you will notify us immediately of any unauthorized
                use of your Account. Cofield takes its account security
                obligations seriously; however, you are responsible for all
                activities that occur under your Account, whether or not you
                know about them.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                3.5 Account Suspension or Termination
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                We may suspend or terminate your access to and use of the
                Services, at our sole discretion, at any time and without notice
                to you, including if (a) you fail to comply with these Terms;
                (b) we suspect fraud, cheating, or misuse by you of Content or
                Services; or (c) we suspect any other unlawful activity
                associated with your Account. If your Account is inactive (i.e.,
                not used or logged-into) for a period of time, we will notify
                you via the Services or in the App prior to termination of your
                Account.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                You may terminate your Account at any time by visiting the Apps
                website. Upon termination of any Services or your Account, the
                following provisions of these Terms will survive: Content
                Ownership, Rights Granted by You, Disclaimer of Warranties,
                Indemnity, Limitation of Liability, Dispute Resolution, General
                Terms and this sentence on Termination.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                3.6 Who May Use Our Services
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Unless stated otherwise for a particular Service, children are
                not allowed to use the Services. No children may access or play
                these Games and Apps. We define “children” as follows:
              </Typography>
              <ul
                style={{
                  marginTop: "10px",
                  paddingLeft: "20px",
                  marginBottom: "20px",
                }}
              >
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Residents of the EEA: anyone under 16 years old, or the age
                    needed to consent to the processing of personal data in your
                    country of residence.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Residents of the Republic of Korea: anyone under 14 years
                    old.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Residents of other regions: anyone under 13 years old or the
                    age needed to consent to the processing of personal data in
                    that region.
                  </Typography>
                </li>
              </ul>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                For Services that permit Child participation, parents or legal
                guardians (" Parents ") must provide verified consent. Parents
                can provide and verify their consent through another authorized
                third-party provider made available through the Service. Where
                Parental consent is required, Cofield recommends that Parents
                monitor the Child’s online activity and use of the Service.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                The verification and consent process for Children is performed
                by one of several third-party providers (" Verification Provider
                "). The Parent must register with the Verification Provider
                before a Child may use the Services. The Verification Provider
                will ask the Parent to verify their identity and to consent to
                the creation of an Account for the Child. Upon receipt of Parent
                verification and consent, the Verification Provider will enable
                the Parent to create an Account for the Child. Parental consent
                applies exclusively to the Service for which it has been
                granted.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                A Parent who wishes to rescind their previously-provided consent
                to a Child’s access to and use of the Services should follow the
                instructions for Account deletion, which can be found on our
                website.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Purchases made through the Services are limited to Account
                holders who either (a) are the age needed to consent to a
                contract in their country of residence; or (b) if younger, have
                the consent of a Parent to use the Service. Parents can consult
                their device settings for the App to restrict in-App purchases
                by a Child, and should also monitor activity in their Child’s
                Account, including the purchase of Virtual Money or Virtual
                Goods.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                TO THE EXTENT PERMITTED UNDER APPLICABLE LAW, COFIELD DECLINES
                ANY RESPONSIBILITY REGARDING ANY ACTIVITIES CONDUCTED BY A CHILD
                WITH OR WITHOUT THE PERMISSION OF A PARENT. IF YOU ARE A PARENT
                AND YOU GIVE YOUR PERMISSION FOR YOUR CHILD TO REGISTER FOR ONE
                OF THE SERVICES, YOU THEREBY AGREE TO THE TERMS RELATING TO USE
                OF THE SERVICES BY YOUR CHILD.
              </Typography>
            </Box>
          </Element>
          <Element mt={4} name="limited-license">
            <Typography variant="h3" mb={1}>
              4. Limited License to Use
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                Subject to your compliance with these Terms, Cofield grants you
                a limited, nonexclusive, nontransferable, non-sublicenseable
                license to download and install a copy of the Apps on a mobile
                device and to run such copy of the Apps solely for your own
                personal non-commercial purposes. Except as expressly permitted
                in these Terms or under applicable law, you may not: (a) copy,
                modify, or create derivative works based on the Apps; (b)
                distribute, transfer, sublicense, lease, lend, or rent the Apps
                to any third party; (c) reverse engineer, decompile, or
                disassemble the Apps; or (d) make the functionality of the Apps
                available to multiple users through any means. Cofield reserves
                all rights in and to the Apps not expressly granted to you under
                these Terms.
              </Typography>
            </Box>
          </Element>
          <Element mt={4} name="content-rights">
            <Typography variant="h3" mb={1}>
              5. Content and Content Rights
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                Subject to your compliance with these Terms, Cofield grants you
                a personal, non-commercial, nonexclusive, nontransferable,
                non-sublicensable, revocable, limited license to download, view,
                display, and use the Content solely for your permitted use
                within the Services. “ Content ” means the text, software,
                interactive maps, Geolocation features, scripts, graphics,
                photos, sounds, music, videos, audiovisual combinations,
                communications, interactive features, works of authorship of any
                kind, and information or other materials that are generated,
                provided, or otherwise made available through the Services,
                including User Content. “ User Content “ means any Content a
                user of a Service provides to be made available through
                Services.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                5.1 Content Ownership
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Cofield does not claim ownership rights in User Content and
                nothing in these Terms restricts any rights that you may have to
                use and exploit your User Content. Subject to the foregoing,
                Cofield and its licensors exclusively own all right, title, and
                interest in and to the Services and Content, including all
                associated intellectual property rights. You acknowledge that
                the Services and Content are protected by copyright, trademark,
                and other laws of Australia and foreign countries. You agree not
                to remove, alter, or obscure any copyright, trademark, service
                mark, or other proprietary rights notices incorporated in or
                accompanying the Services or Content.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                5.2 Rights Granted by You
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                By making any User Content available through the Services you
                grant to Cofield a nonexclusive, transferable, sublicenseable,
                worldwide, royalty-free, perpetual license (or, if not permitted
                under applicable law, a license for the whole duration,
                including for any extension thereof, of all relevant rights
                under any applicable law), to use, copy, modify, create
                derivative works based upon, publicly display, publicly perform,
                market, promote and distribute your User Content in connection
                with operating and providing the Services and Content to you and
                to others. By accepting these terms, you allow Cofield to
                benefit freely from the above rights, including but not limited
                to:
              </Typography>
              <ol
                style={{
                  marginTop: "10px",
                  paddingLeft: "20px",
                  marginBottom: "20px",
                }}
              >
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    The right to reproduce User Content by any means and in any
                    form.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    The right to publicly or privately broadcast or make
                    available the User Content (or any product incorporating the
                    User Content), in return for payment or free of charge in
                    all places by any means or process known or unknown at the
                    present time, and in particular via Internet, pay per view,
                    pay per play, theatrical or television broadcasting, DVD,
                    and print.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    The right to use the User Content for demonstration,
                    promotion and advertising for all Cofield Services.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    The right to produce or order the production of any new
                    product or service from the User Content or from any product
                    incorporating or exploiting the User Content, either
                    reproduced as it stands or modified by Cofield or by any
                    outside party of its choice.
                  </Typography>
                </li>
              </ol>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                You are solely responsible for all your User Content. You
                represent and warrant that you own all your User Content or you
                have all rights that are necessary to grant us the license
                rights in your User Content under these Terms. You also
                represent and warrant that neither your User Content, nor your
                use and provision of your User Content to be made available
                through the Services, nor any use of your User Content by
                Cofield on or through the Services will infringe, misappropriate
                or violate a third party’s intellectual property rights, or
                rights of publicity or privacy, or result in the violation of
                any applicable law or regulation. To the extent permitted by
                applicable law, you also agree that you will not exercise your
                moral rights (or equivalent rights under applicable laws), such
                as your right to be identified as the author of any of the User
                Contents, against Cofield or any third party designated by
                Cofield.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                5.3 Trading
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Certain App current or future add-on, permanent or exclusive
                event features permit Account holders to capture and trade
                virtual items, including but not limited characters or other
                items (" Trading Items "), Whenever and wherever available
                during gameplay. Unlike Virtual Money and Virtual Goods, Trading
                Items are obtained at no additional charge during gameplay.
                Trading Items are a category of Content, and you acknowledge
                that you do not acquire any ownership rights in or to Trading
                Items and that Trading Items do not have monetary value. Trading
                Items may be traded with other Account holders for other Trading
                Items, but Trading Items can never be sold, transferred, or
                exchanged for Virtual Money, Virtual Goods, “real” goods, “real”
                money, or “real” services, or any other consideration from us or
                anyone else.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                You agree that you will only obtain Trading Items from other
                Account holders and through means provided by Cofield, and not
                through any third-party platform, broker, or other mechanism,
                unless expressly authorized. Any such sale, transfer, or
                exchange (or attempt to do so) is prohibited and may result in
                the termination of your Account or cancellation of such Trading
                Items. All Trading Items and other Content are provided "as is,"
                without any warranty, except where prohibited under applicable
                law.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                5.4 Virtual Money and Virtual Goods
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Our Apps permit the purchase of virtual currency (" Virtual
                Money "), specific to each App, and use of that Virtual Money to
                purchase virtual items or services expressly available for use
                in the respective Apps (“ Virtual Goods “). Virtual Money is a
                category of Content. You may access and purchase Virtual Goods
                for your personal, non-commercial use of the Services. You
                acknowledge that you do not acquire any ownership rights in or
                to the Virtual Money or Virtual Goods. Any balance of Virtual
                Goods or Virtual Money does not reflect any stored value and you
                agree that Virtual Money and Virtual Goods have no monetary
                value and do not constitute currency or property of any type.
                Virtual Money may be redeemed for Virtual Goods, and can never
                be sold or, transferred, Virtual Money can however be exchanged
                for “real” money and paid out in form secure bank transaction to
                using third party secure banking systems. You agree that you
                will only obtain Virtual Money and/or Virtual Goods from us and
                through means provided by us, and not from any third party
                unless expressly authorized. When you purchase Virtual Money
                and/or Virtual Goods from an authorized third party, Cofield is
                not a party to the transaction and your purchase will be
                governed by the third party’s payment terms and conditions.
                Please review the applicable third party’s terms of service for
                additional information. Once you acquire a license to Virtual
                Money or Virtual Goods, you may not transfer them to another
                individual or account. Any such sale, transfer, or exchange (or
                attempt to do so) is prohibited, is a violation of these Terms
                and may result in cancellation of such Virtual Money or Virtual
                Goods or the termination of your Account.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                During the term of your license to your Virtual Money, you may
                redeem your Virtual Money for selected Virtual Goods. As set
                forth below, all Virtual Money, Virtual Goods, and other Content
                is provided "as is," without any warranty. You agree that all
                sales by us to you of Virtual Money and Virtual Goods are final
                and that we will not permit exchanges or refunds for any unused
                Virtual Money or Virtual Goods once the transaction has been
                made.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Generally, we have the right to offer, modify, eliminate, and/or
                terminate Virtual Money, Virtual Goods, the Content, and/or the
                Services, or any portion thereof, at any time, without notice or
                liability to you. If we discontinue the use of Virtual Money or
                Virtual Goods, we may provide you with reasonable advance notice
                by posting a notice through the Services or through other
                communications.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                5.5 Feedback
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                You can submit feedback, comments, and suggestions for
                improvements to the Services (" Feedback ") by reaching out to
                us on social media or support channels. Feedback is a form of
                User Content.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                5.6 Intellectual Property Policy
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Cofield respects intellectual property rights and expects its
                users to do the same. It is Cofield’s policy to remove content
                that infringes on a third party’s intellectual property rights,
                and Cofield reserves the right to terminate Accounts that engage
                in repeat infringement. Cofield complies the relevant
                legislation is the Copyright Act 1968 and will respond to a
                valid claim of copyright infringement in accordance with the
                copyright Act 1968 process. Please see Cofield’s{" "}
                <Link
                  to="https://www.nianticlabs.com/ip-policy?hl=en"
                  target="_blank"
                >
                  Intellectual Property
                </Link>{" "}
                Policy for further information.
              </Typography>
            </Box>
          </Element>
          <Element mt={4} name="conduct">
            <Typography variant="h3" mb={1}>
              6. Conduct, General Prohibitions, and Cofield’s Enforcement Rights
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                You agree that you are responsible for your own conduct and User
                Content while using the Services, and for any consequences
                thereof. In addition, you agree not to do any of the following,
                unless applicable law mandates that you be given the right to do
                so:
              </Typography>
              <ul
                style={{
                  marginTop: "10px",
                  paddingLeft: "20px",
                  marginBottom: "20px",
                }}
              >
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    collect, store or share any personally identifiable
                    information of other users from the Services without their
                    express permission;
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    extract, scrape, or index the Services or Content (including
                    information about users or gameplay);
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    use the Services or Content, or any portion thereof, for any
                    commercial purpose or in a manner not permitted by these
                    Terms, including but not limited to (a) gathering in-App
                    items or resources for sale outside the Apps, (b) performing
                    services in the Apps in exchange for payment outside the
                    Apps, or (c) selling, reselling, or renting the Apps or your
                    Account;
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    attempt to access or search the Services or Content or
                    download Content from the Services through the use of any
                    technology or means other than those provided by Cofield or
                    other generally available third party web browsers
                    (including without limitation automation software, bots,
                    spiders, crawlers, data mining tools, or hacks, tools,
                    agents, engines, or devices of any kind);
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    attempt to decipher, decompile, disassemble, or reverse
                    engineer any of the software used to provide the Services or
                    Content;
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    bypass, remove, deactivate, descramble, or otherwise
                    circumvent any technological measure implemented by Cofield
                    or any of Cofield’s providers or any other third party
                    (including another user) to protect the Services or Content;
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    use, display, mirror, or frame the Services or any
                    individual element within the Services, Cofield’s name, any
                    Cofield trademark, logo, or other proprietary information,
                    or the layout and design of any page or App without
                    Cofield’s express written consent;
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    post, publish, submit, store, or transmit any Content that
                    infringes, misappropriates, or violates a third party’s
                    patent, copyright, trademark, trade secret, moral rights, or
                    other intellectual property rights, or rights of publicity
                    or privacy;
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    access, tamper with, or use non-public areas of the
                    Services, Cofield’s computer systems, or the technical
                    delivery systems of Cofield’s providers;
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    attempt to probe, scan, or test the vulnerability of any
                    Cofield system or network or Service, or breach any security
                    or authentication measures;
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    use any meta tags or other hidden text or metadata utilizing
                    a Cofield trademark, logo, URL, or product name without
                    Cofield’s express written consent;
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    forge any TCP/IP packet header or any part of the header
                    information in any email or newsgroup posting, or in any way
                    use the Services or Content to send altered, deceptive, or
                    false source identifying information;
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    interfere with, or attempt to interfere with, the access of
                    any user, host, or network, including, without limitation,
                    sending a virus, overloading, flooding, spamming, or
                    mailbombing the Services;
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    delete, obscure, or in any manner alter any attribution,
                    warning, or link that appears in the Services or the
                    Content;
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    violate any applicable law or regulation; or
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    encourage or enable any other individual to do any of the
                    foregoing.
                  </Typography>
                </li>
              </ul>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                Although Cofield is not obligated to monitor access to or use of
                the Services or Content or to review or edit any Content, we
                have the right to do so for the purpose of operating the
                Services, to ensure compliance with these Terms, and to comply
                with applicable law or other legal requirements. We reserve the
                right to remove or disable access to any Content, at any time
                and without notice. Cofield may remove any Content we consider
                to be objectionable or in violation of these Terms. We have the
                right to investigate violations of these Terms or conduct that
                affects the Services. We may also consult and cooperate with law
                enforcement authorities to prosecute users and others who
                violate the law.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                ANY ATTEMPT BY YOU TO DISRUPT OR INTERFERE WITH THE SERVICES,
                INCLUDING WITHOUT LIMITATION UNDERMINING OR MANIPULATING THE
                LEGITIMATE OPERATION OF ANY SITE OR APP, IS A BREACH OF
                COFIELD’S TERMS AND MAY BE A BREACH OR VIOLATION OF CRIMINAL AND
                CIVIL LAWS.
              </Typography>
            </Box>
          </Element>
          <Element mt={4} name="events">
            <Typography variant="h3" mb={1}>
              7. Participation in Events
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                7.1 Event Registration and Tickets
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                The term "**Event(s)**" means any in-person event, gathering,
                activity or the like which is directly organized, hosted, or
                managed by Cofield, and any Promotion (as defined below). By
                registering or, where required, purchasing tickets (including
                any optional add-on purchases of additional gameplay experiences
                and/or physical merchandise) for an Event, you represent and
                warrant that the information you provide is true and accurate.
                If you are registering or purchasing tickets on behalf of
                others, you represent and warrant you have all necessary rights
                and consents to register and provide this information for
                others.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Subject to applicable law and the exceptions set forth in these
                Terms, no refunds or exchanges of Event tickets (including any
                add-on purchases) are permitted and tickets are
                non-transferable. Reasonably acceptable proof of identity, for
                example a driver’s license or passport, showing the same first
                and last name as those provided at time of prior registration,
                may be required to access an Event. Actual or attempted resale
                of tickets subjects them to revocation without refund. Tickets
                obtained from unauthorized sources may be invalid, lost, stolen,
                or counterfeit and may not be honoured. Tickets cannot be
                replaced if lost, stolen or destroyed. Commercial use of tickets
                is prohibited without written approval from Cofield. Tickets are
                not redeemable for cash or credit. You agree to abide by any
                published ticket limits or restrictions, and orders exceeding or
                violating these restrictions are subject to cancellation without
                notice or refund. Events may have limited space and/or
                availability and Cofield does not guarantee your ability to
                purchase a ticket or attend an Event.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Unless otherwise prohibited under applicable law, by attending
                an Event you acknowledge that Cofield will use your data
                collected pursuant to the Privacy Policy for providing Event
                features (both in person and online), including contacting you
                and giving you updates about the Event, mailing you required
                materials (e.g., a QR wristband), providing emergency or severe
                weather notifications, or public Event leaderboards and gameplay
                competitions.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                7.2 Event Conduct and Policies
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                You shall at all times comply with all applicable laws and any
                rules and policies provided by Cofield or any other authorized
                party involved in creating or delivering the Event, including
                all health and safety policies and procedures and all reasonable
                instructions of the venue staff and Cofield representatives at
                the Event. As a condition of participation, you agree to comply
                with all policies on the Sites, including, without limitation,
                any applicable Event website and the Cofield Live Event Code of
                Conduct.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Illicit drugs, controlled substances, contraband, weapons and
                illegal items are prohibited at Events. You agree and consent to
                reasonable security precautions and search on entry. To the
                fullest extent permitted by applicable law, you waive, and
                release Cofield and any other party involved in creating or
                delivering the Event from any and all claims, demands, causes of
                action, damages, losses, expenses or liability which may arise
                out of, result from, or relate in any way to such security
                precautions and/or searches. If you elect not to consent to such
                security precautions and searches, you may be denied entry, or
                removed from, an Event without refund or other compensation.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Cofield and its authorized third parties reserve the right to
                refuse admission to, or to remove from an Event without refund
                or compensation of any kind, any person that (a) does not comply
                with these Terms, (b) engages in disorderly conduct or willful
                misconduct,(c) engages in harassing, threatening, abusive or
                otherwise inappropriate behaviour towards Cofield employees,
                event staff or other event attendees, or (d) Cofield or its
                authorized third parties believe will cause a negative effect on
                the Event, participants, spectators, and/or personnel.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Any minor attending an Event must be accompanied by a Parent.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                7.3 Assumption of Risks
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Unless prohibited by applicable law, you agree that by
                purchasing tickets to, participating in or attending an Event,
                you willingly, knowingly and voluntarily assume any and all
                risks occurring before, during or after the Event, including
                injury by any cause and damage, loss, or theft of property. You
                acknowledge that Events, and certain activities at Events, have
                inherent and unforeseen risks, including but not limited to (a)
                contact or collision with persons or objects, (b) obstacles
                (e.g., natural and man-made water, road and surface hazards),
                (c) equipment related hazards (e.g., broken, defective or
                inadequate equipment, unexpected equipment failure), (d) weather
                related hazards, (e) inadequate first aid and/or emergency
                measures, (f) judgment and/or behaviour related problems (e.g.,
                erratic or inappropriate participant, co-participant, or
                spectator behaviour or errors in judgment by personnel at the
                Event), and (g) natural hazards (e.g., uneven or difficult
                terrain, wildlife and insects, contact with plants). You agree
                to take reasonable precautions before attending or participating
                in an Event and its activities, for example consulting with a
                personal physician and ensuring you are in good physical health,
                wearing appropriate attire, and bringing necessary or
                recommended supplies. You further understand and acknowledge it
                is your responsibility to inspect the Event grounds, facilities,
                equipment and areas to be used, and that by participating in the
                Event, you acknowledge the Event grounds, facilities, equipment,
                and areas to be used are safe, adequate, and acceptable for
                participation. If you believe or become aware of any unsafe
                conditions or unreasonable risks, you agree to immediately
                notify appropriate personnel and cease participation in the
                Event.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                To the extent permitted under applicable law, you hereby waive
                and release Cofield and any other party involved in creating or
                delivering the Event from any and all claims, demands, causes of
                action, damages, losses, expenses or liability which may arise
                out of, result from, or relate in any way to your attendance or
                participation in an Event, including for negligence, inherent
                and unforeseen risks, injury or damage to persons or property
                and the actions of third parties or Event participants and
                spectators.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                7.4 Event Features and Cancellation
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Subject to applicable law, all schedules and any live or in-game
                experiences, activities, goods, services, perks, items, rewards
                and/or Content (collectively " Event Features ") advertised in
                connection with an Event are not guaranteed and are subject to
                change and/or cancellation at any time prior to or during an
                Event without notice or compensation of any kind. Admittance to
                an Event does not guarantee any specific Event Features while at
                the Event.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Event date, time and/or location are subject to change at any
                time, and Cofield will make a commercially reasonable effort to
                notify you in advance of any material changes. If an Event is
                cancelled, suspended, or rescheduled and you are not able to
                attend, you will not be entitled to any compensation other than
                a refund of the ticket price at its face value with no further
                liability or compensation from Cofield or any other party. Any
                travel or accommodation costs incurred are entirely your
                responsibility.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                7.5 Recordings and Use of Likeness
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                You consent to and approve of Cofield’s recording of your image,
                likeness, name, dialogue, biographic information, personal
                characteristics, and voice at Events and the royalty free use of
                this information subject to the same "Rights Granted by You"
                above. Cofield may publish the results of any competitions
                (including rankings and any winners), gameplay statistics, and
                pictures of participants in promotional and marketing materials
                and on social media in accordance with these Terms.
              </Typography>
            </Box>
          </Element>
          <Element mt={4} name="promotions">
            <Typography variant="h3" mb={1}>
              8. Events, Promotions, Contests, Surveys
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Periodically, Cofield and/or its partners may organize events,
                promotion contests, surveys, event games, and similar promotions
                on the Services (each a "Promotion"). In addition to these
                Terms, Promotions will be subject to particular terms which we
                shall communicate to you at the time of these Promotions
                (“Promotional Terms“). By participating in any Promotion, you
                will become subject to those Promotional Terms. All Promotional
                Terms are incorporated into, may vary from, and shall supersede
                these Terms. Cofield urges you to read the Promotional Terms.
                Our Privacy Policy, in addition to these Terms and any
                Promotional Terms, governs any information you submit in
                connection with such Promotions.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Figrun Contest Official Rules
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                NO PURCHASE OR PAYMENT OF ANY KIND IS NECESSARY TO ENTER OR WIN.
                A PURCHASE DOES NOT INCREASE YOUR CHANCES OF WINNING. VOID WHERE
                PROHIBITED OR RESTRICTED BY LAW. YOU WIN SOME, YOU LOSE MANY
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                By participating in the Figrun contest (the "Contest"), you
                agree to be bound by these official rules (the "Official Rules")
                and by the decisions of Figrun, which are final and binding in
                all respects.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                1. Eligibility
              </Typography>
              <ul
                style={{
                  paddingLeft: "20px",
                  marginBottom: "20px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  The Contest is open to individuals who:
                </Typography>
                <li
                  style={{
                    margin: "0 20px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Are legal residents of their respective countries.
                  </Typography>
                </li>
                <li
                  style={{
                    margin: "0 20px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Are at least 13 years of age or older (with
                    parental/guardian consent if under 18).
                  </Typography>
                </li>
                <li
                  style={{
                    margin: "0 20px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Have access to the Figrun app via a compatible device and a
                    valid user account.
                  </Typography>
                </li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  The Contest is void where prohibited by law. Employees,
                  officers, and directors of Figrun, its affiliates,
                  subsidiaries, advertising, promotion, and fulfillment
                  agencies, and their immediate family members are not eligible
                  to participate.
                </Typography>
              </ul>
            </Box>
            <Box mt={0.5} mb={2}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                2. Contest Period
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000" }}
              >
                The Contest begins and ends at the times specified in the
                promotional announcement within the app (the "Contest Period").
                Entries received outside the Contest Period will not be
                considered.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                3. How to Enter
              </Typography>
              <ul
                style={{
                  paddingLeft: "20px",
                  marginBottom: "20px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  To enter the Contest:
                </Typography>
                <li
                  style={{
                    margin: "0 20px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Download or open the Figrun app.
                  </Typography>
                </li>
                <li
                  style={{
                    margin: "0 20px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Complete the designated in-app activity
                  </Typography>
                </li>
                <li
                  style={{
                    margin: "0 20px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Reaching locations physically to unlock figs, or virtually
                    through the app and by, completing challenges, or milestones
                  </Typography>
                </li>
                <li
                  style={{
                    margin: "0 20px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Participants must complete the activities within the Contest
                    Period to be eligible.
                  </Typography>
                </li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Limit: One entry per user unless otherwise stated in the
                  Promotional Terms.
                </Typography>
              </ul>
            </Box>
            <Box mt={0.5} mb={2}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                4. Prizes
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000" }}
              >
                The prizes for the Contest are as described in the in-app
                promotional materials. Prizes may include cash, in-app currency
                (Gems), merchandise, or other items offered by third party
                partnerships, retail stores, commercial deals and or any
                official deals offered by figrun. The number of winners, prize
                values, and total prize amounts will be specified for each
                Contest. Prizes are non-transferable and may not be substituted
                for cash unless otherwise provided by Figrun. Figrun reserves
                the right to substitute any prize with one of equal or greater
                value.
              </Typography>
            </Box>
            <Box mt={0.5} mb={2}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                5. Winner Selection and Notification
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000" }}
              >
                Winners will be selected based on the criteria outlined in the
                promotional materials for each specific Contest. These may
                include random draws, skill-based achievements, or other
                methods. Winners will be notified via email or in-app
                notifications within 5 business days of the end of the Contest
                Period. If a winner does not respond to the notification within
                48 hours or is disqualified for any reason, an alternate winner
                may be selected at Figrun’s sole discretion.
              </Typography>
            </Box>
            <Box mt={0.5} mb={2}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                6. Taxes
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000" }}
              >
                All federal, state, and local taxes, fees, and surcharges, if
                any, on prizes are the sole responsibility of the prize winners.
                Winners may be required to provide additional information for
                tax purposes, such as a social security number (for tax
                reporting in the U.S.) or other identification.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                7. General Conditions
              </Typography>
              <ul
                style={{
                  paddingLeft: "20px",
                  marginBottom: "20px",
                }}
              >
                <li
                  style={{
                    margin: "0 20px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Figrun reserves the right to modify or cancel the Contest at
                    any time without prior notice.
                  </Typography>
                </li>
                <li
                  style={{
                    margin: "0 20px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Figrun is not responsible for late, lost, damaged,
                    incomplete, or misdirected entries.
                  </Typography>
                </li>
                <li
                  style={{
                    margin: "0 20px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    In the event of a dispute over the identity of an entrant,
                    the entry will be deemed to have been submitted by the
                    registered account holder at the time of entry.
                  </Typography>
                </li>
              </ul>
            </Box>
            <Box mt={0.5} mb={2}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                8. Disqualification
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000" }}
              >
                Figrun reserves the right to disqualify any entrant found to be
                tampering with the entry process or the operation of the Contest
                or to be acting in violation of these Official Rules or any
                other promotion or in an unsportsmanlike or disruptive manner.
              </Typography>
            </Box>
            <Box mt={0.5} mb={2}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                9. Limitation of Liability
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000" }}
              >
                By entering the Contest, you agree to release and hold harmless
                Figrun, its affiliates, and each of their respective officers,
                directors, employees, and agents from any and all liability for
                injuries, loss, or damage of any kind arising from or in
                connection with the Contest, including but not limited to the
                receipt, use, or misuse of any prize.
              </Typography>
            </Box>
            <Box mt={0.5} mb={2}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                10. Dispute Resolution
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000" }}
              >
                Any dispute arising from or related to the Contest will be
                governed by the laws of the jurisdiction of Figrun's
                headquarters, without regard to its conflict of laws principles,
                and will be resolved exclusively through binding arbitration.
              </Typography>
            </Box>
            <Box mt={0.5} mb={2}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                11. Privacy
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000" }}
              >
                Any personal information collected during the course of the
                Contest will be handled in accordance with Figrun's Privacy
                Policy.
              </Typography>
            </Box>
            <Box mt={0.5} mb={2}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                12. Winners List
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000" }}
              >
                To request the list of winners, send a self-addressed stamped
                envelope to the Contest address provided in the promotional
                material. Requests must be received within 30 days of the end of
                the Contest Period.
              </Typography>
            </Box>
            <Box mt={0.5} mb={2}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                13. Apple is Not a Sponsor
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000" }}
              >
                The Contest or sweepstakes conducted by Figrun is in no way
                sponsored, endorsed, administered by, or associated with Apple
                Inc. By participating, you understand that you are providing
                your information to Figrun and not to Apple. All questions,
                comments, or complaints regarding the Contest should be directed
                to Figrun, not Apple.
              </Typography>
            </Box>
          </Element>
          <Element mt={4} name="third-party">
            <Typography variant="h3" mb={1}>
              9. Third Party Websites or Resources
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                Services may contain links to third party websites or resources.
                Cofield provides these links only as a convenience and is not
                responsible for the content, products, or services on or
                available from those websites or resources, or links displayed
                on such websites. To the extent permitted under applicable law,
                you acknowledge sole responsibility for and assume all risk
                arising from, your use of any third-party websites or resources.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                Cofield is not responsible for the availability or quality of
                third-party services, including cell phone networks, hotspots,
                wireless internet and other services. Such third-party services
                may affect your ability to utilize the Services or participate
                in an Event and you hereby waive and release Cofield and any
                other party involved in creating or delivering the Services from
                all claims, demands, causes of action, damages, losses, expenses
                or liability which may arise out of, result from, or relate in
                any way to such third party services.
              </Typography>
            </Box>
          </Element>
          <Element mt={4} name="warranties">
            <Typography variant="h3" mb={1}>
              10. Disclaimer of Warranties
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                To the extent permitted under applicable law, the services and
                content are provided "As is," without warranty of any kind.
                Without limiting the foregoing, we explicitly disclaim any
                warranties of merchantability, fitness for a particular purpose,
                quiet enjoyment, or noninfringement, and any warranties arising
                out of course of dealing or usage of trade. We make no warranty
                that the services will meet your requirements or be available on
                an uninterrupted, secure, or error-free basis. We make no
                warranty regarding the quality, accuracy, timeliness,
                truthfulness, completeness or reliability of any content.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                You assume all risks relating to your online or offline
                communications and interactions with other users of the services
                and with other persons with whom you communicate or interact as
                a result of your use of the services. You understand that
                cofield does not screen or inquire into the background of any
                users of the services. Cofield makes no representations or
                warranties as to the conduct of users of the services. You agree
                to take reasonable precautions in all communications and
                interactions with other users of the services and with other
                persons with whom you communicate or interact as a result of
                your use of the services, particularly if you decide to meet
                offline or in person.
              </Typography>
            </Box>
          </Element>
          <Element mt={4} name="liability">
            <Typography variant="h3" mb={1}>
              11. Limitation of Liability
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                To the extent permitted under applicable law, neither cofield
                nor any other party involved in creating, producing, or
                delivering the services or content will be liable to you for any
                indirect, incidental, special, punitive, exemplary, or
                consequential damages, including lost profits, loss of data or
                goodwill, service interruption, computer damage or system
                failure or the cost of substitute services, arising out of or in
                connection with these terms, or from the use of or inability to
                use the services or content, or from any communications,
                interactions, or meetings with other users of the services or
                persons with whom you communicate or interact as a result of
                your use of the services, whether based on warranty, contract,
                tort (including negligence), product liability, or any other
                legal theory, and whether or not cofield has been advised of the
                possibility of such damages, even if a limited remedy set forth
                herein is found to have failed of its essential purpose. Some
                jurisdictions do not allow the exclusion or limitation of
                liability for consequential or incidental damages, so the
                foregoing limitation of liability shall apply to the fullest
                extent permitted by law in the applicable jurisdiction.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                To the extent permitted under applicable law, in no event will
                cofield’s total liability arising out of or in connection with
                these terms, an event, or from the use of or inability to use
                the services or content exceed one thousand dollars aud ($1000),
                or, if contracting with cofield international limited, one
                thousand us ($1000). The exclusions and limitations of damages
                set forth above are fundamental elements of the basis of the
                bargain between cofield and you.
              </Typography>
            </Box>
          </Element>
          <Element mt={4} name="dispute">
            <Typography variant="h3" mb={1}>
              12. Dispute Resolution
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                You agree that disputes between you and cofield will be resolved
                by binding, individual arbitration, and you are waiving your
                right to a trial by jury or to participate as a plaintiff or
                class member in any purported class action or representative
                proceeding.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                This notice does not apply: (1) if you are a resident of the
                eea, or any jurisdiction which does not allow this arbitration
                agreement, (2) if you opt out of arbitration as described in the
                "Arbitration" section below, or (3) to certain types of disputes
                described in section 13.1, “arbitration,” below.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                12.1 Arbitration
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                If you live in the US or another jurisdiction which allows you
                to agree to arbitration, you and Cofield agree that any disputes
                will be settled by binding arbitration, except that each party
                retains the right: (a) to bring an individual action in small
                claims court and (b) to seek injunctive or other equitable
                relief in a court of competent jurisdiction to prevent the
                actual or threatened infringement, misappropriation, or
                violation of a party’s copyrights, trademarks, trade secrets,
                patents, or other intellectual property rights (the action
                described in this clause (b), an " IP Protection Action ").
                Notwithstanding this arbitration agreement, Cofield reserves the
                right to bring an action in any court of competent jurisdiction
                against you to stop and/or seek compensation for the intentional
                or willful misuse or abuse (e.g. hacking or falsifying location)
                of its IP, products, and Services.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Without limiting the preceding paragraph, you will also have the
                right to litigate any other dispute if you provide Cofield with
                written notice of your desire to do so by email to{" "}
                <Link to={"mailto:info@cofield.au"}>info@cofield.au</Link>{" "}
                within thirty (10) days following the date you first accept
                these Terms (such notice, an " Arbitration Opt-out Notice "). If
                you don’t provide Cofield with an Arbitration Opt-out Notice
                within the thirty (10) day period, you will be deemed to have
                knowingly and intentionally waived your right to litigate any
                dispute except as expressly set forth in clauses (a) and (b)
                above. Further, unless both you and Cofield otherwise agree in
                writing, the arbitrator may not consolidate more than one
                person’s claims and may not otherwise preside over any form of
                any class or representative proceeding. If this class action
                waiver is held unenforceable, then the parties’ agreement to
                arbitrate will be deemed void. Except as provided in the
                preceding sentence, this “Dispute Resolution” section will
                survive any termination of these Terms. If the terms of this
                Section 13.1 “Arbitration” are found unenforceable as to any
                claim for relief, that claim must be severed from the
                arbitration and brought pursuant to Section 13.6, “Governing Law
                and Exclusive Venue.” All other claims will be arbitrated. The
                arbitrator, and not any court or agency, shall have exclusive
                authority to (a) determine the scope and enforceability of this
                arbitration agreement and (b) resolve any dispute related to its
                interpretation, applicability, enforceability, or formation
                including any claim that all or any part of it is void or
                voidable.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                12.2 Arbitration Rules
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                <b>Governing Law :</b> This Agreement shall be governed by and
                construed in accordance with the laws of Australia.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                <b>Dispute Resolution :</b> In the event of a dispute arising
                under or relating to this Agreement, the services provided by
                cofield pty ltd, or any other claims or disputes relating to the
                usage of the cofield owned and operated apps, the parties agree
                first to attempt to resolve the dispute informally. If the
                parties are unable to resolve the dispute informally, they agree
                to submit the dispute to binding arbitration. The arbitration
                will be conducted under the rules of the Australian Centre for
                International Commercial Arbitration (ACICA).
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                <b>Arbitration Process :</b> The arbitration will be conducted
                by a single arbitrator, selected in accordance with the ACICA
                rules. The arbitration will be held in the English language and
                the arbitral decision may be enforced in any court. The
                prevailing party in the arbitration shall be entitled to receive
                reimbursement of its reasonable expenses incurred in connection
                with the arbitration.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                <b>Restriction on Class Actions and Remedies :</b> All
                arbitrations under this Agreement shall be conducted on an
                individual basis. Class arbitrations and class actions are not
                permitted, and you are agreeing to give up the ability to
                participate in a class action.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                12.3 Arbitration Process
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                A party who desires to initiate arbitration must provide the
                other party with a written Demand for Arbitration as specified
                in the ACICA Rules. (The ACICA provides a general Demand for
                Arbitration) The single arbitrator will be either a retired
                judge or an attorney licensed to practice law and will be
                selected by the parties from the ACICA’s roster of arbitrators.
                If the parties are unable to agree upon an arbitrator within
                fourteen (14) days of delivery of the Demand for Arbitration,
                then the ACICA will appoint the arbitrator in accordance with
                the ACICA’s Rules.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                12.4 Arbitration Location and Procedure
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Unless you and Cofield otherwise agree, the arbitration will be
                conducted in a confidential manner, in the county where you
                reside. If your claim does not exceed $5,000 AUD, then the
                arbitration will be conducted solely on the basis of the
                documents that you and Cofield submit to the arbitrator, and
                there will be no other discovery conducted (such as
                depositions), unless the arbitrator determines that a hearing is
                necessary. If your claim exceeds $5,000, your right to a hearing
                will be determined by the ACICA Rules. Subject to the ACICA
                Rules, the arbitrator will have the discretion to direct a
                reasonable exchange of information by the parties, consistent
                with the expedited nature of the arbitration. Notwithstanding
                the arbitrator’s discretion, absent a showing of good cause, in
                no event shall the parties be allowed more than three (3)
                depositions per side, and there will be no corporate deposition
                of the type contemplated by Federal Rule of Civil Procedure
                30(b)(6) and New South Wales : Governed by the Uniform Civil
                Procedure Rules 2005 .
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                12.5 Arbitrator’s Decision
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                The arbitrator will render an award within the time frame
                specified in the ACICA Rules. The arbitrator’s decision will be
                treated as confidential, and will include the essential findings
                and conclusions upon which the arbitrator based the award.
                Confirmation and enforcement of the arbitration award may be
                done in any court of competent jurisdiction. The arbitrator’s
                award of damages must be consistent with the terms of Section 12
                "Limitation of Liability" as to the types and amounts of damages
                for which a party may be held liable. The arbitrator may award
                declaratory or injunctive relief only in favour of the claimant
                and only to the extent necessary to provide relief warranted by
                the claimant’s individual claim. If you prevail in arbitration,
                you will be entitled to an award of attorneys’ fees and expenses
                to the extent provided under applicable law. Cofield will not
                seek, and hereby waives, all rights it may have under applicable
                law to recover attorneys’ fees and expenses if it prevails in
                arbitration.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                12.6 Governing Law and Exclusive Venue
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                To the extent that these Terms allow you or Cofield to initiate
                litigation in a court, other than for small claims court
                actions, both you and Cofield agree to the exclusive
                jurisdiction of and venue in the state and federal courts
                located in New South Wales, Australia. Each of the parties
                hereto waives any objection to jurisdiction and venue in such
                courts. These Terms and your use of the Services are governed by
                the laws of the State New South Wales, excluding its
                conflicts-of-law rules. If you are resident in a member state of
                the EEA or a country in which this clause is prohibited by local
                law, this section does not apply to you, and does not deprive
                you of the protection of the mandatory provisions of the
                consumer protection laws in your country.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                12.7 Fees
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Our responsibility to pay any ACICA filing, administrative, and
                arbitrator fees will be solely as set forth in the ACICA Rules.
                However, if your claim for damages does not exceed AUD $75,000,
                Cofield will pay all such fees unless the arbitrator finds that
                either the substance of your claim or the relief sought in your
                Demand for Arbitration was frivolous or was brought for an
                improper purpose (as measured by the standards set forth in
                Federal Rules of Civil Procedure 11(b)).
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                12.8 Changes to Dispute Resolution
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Notwithstanding the provisions of the "Changes to Terms or
                Services" section above, if Cofield changes this “Dispute
                Resolution” section after the date you first accepted these
                Terms (or accepted any subsequent changes to these Terms), you
                may reject any such change by sending us written notice (by
                email <Link to={"mailto:info@cofield.au"}>info@cofield.au</Link>{" "}
                ) within thirty (10) days of the date such change became
                effective, as indicated in the “Last Updated” date above or in
                the date of Cofield’s email to you notifying you of such change.
                By rejecting any change, you are agreeing that you will
                arbitrate any Dispute between you and Cofield in accordance with
                the provisions of this “Dispute Resolution” section as of the
                date you first accepted these Terms (or accepted any subsequent
                changes to these Terms).
              </Typography>
            </Box>
          </Element>
          <Element mt={4} name="general">
            <Typography variant="h3" mb={1}>
              13. General
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                13.1 Entire Agreement
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                These Terms constitute the entire and exclusive understanding
                and agreement between Cofield and you regarding the Services and
                Content, and these Terms supersede and replace any and all prior
                oral or written understandings or agreements between Cofield and
                you regarding the Services and Content.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                13.2 Severability
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                If any provision of these Terms is held invalid or
                unenforceable, that provision will be enforced to the maximum
                extent permissible and the other provisions of these Terms will
                remain in full force and effect. You may not assign or transfer
                these Terms, by operation of law or otherwise, without Cofield’s
                prior written consent. Any attempt by you to assign or transfer
                these Terms, without such consent, will be null. Cofield may
                freely assign or transfer these Terms without restriction, and
                the transferor or assignor shall not remain jointly and
                severally liable. Subject to the foregoing, these Terms will
                bind and inure to the benefit of the parties, their successors
                and permitted assigns.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                13.3 Force Majeure
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Neither Cofield, any user, nor any other party involved in
                creating, producing, or delivering the Services or Content shall
                be liable with respect to any damages, injuries, non-performance
                or delay in performance by reason of any act of God, weather,
                fire, flood, acts of terror or foreign enemy, satellite or
                network failure, governmental order or regulation, trade
                dispute, or any other cause beyond its respective control.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                13.4 Notice
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Any notices or other communications provided by Cofield under
                these Terms, including those regarding modifications to these
                Terms, will be given: (a) via email; or (b) by posting to the
                Services. For notices made by email, the date of receipt will be
                deemed the date on which such notice is transmitted to any email
                address Your provided.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                13.5 Waiver
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Cofield’s failure to enforce any right or provision of these
                Terms will not be considered a waiver of such right or
                provision. The waiver of any such right or provision will be
                effective only if in writing and signed by a duly authorized
                representative of Cofield. Except as expressly set forth in
                these Terms, the exercise by either party of any of its remedies
                under these Terms will be without prejudice to its other
                remedies under these Terms or otherwise.
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                13.6 Contact Information
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                If you have any questions about these Terms or the Services,
                please contact Cofield at{" "}
                <Link to={"mailto:info@cofield.au"}>info@cofield.au</Link>
              </Typography>
            </Box>
          </Element>
          <Element mt={4} name="korea">
            <Typography variant="h3" mb={1}>
              14. Terms Specific to Residents of the Republic of Korea
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                14.1 Purchases by End Users in the Republic of Korea
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                If you live in the Republic of Korea, the E-Commerce Act
                provides you with certain rights to refunds within seven (7)
                days of purchase. However, please note that once you exchange
                Virtual Money for Virtual Goods within the App, a refund will no
                longer be available. We reserve the right to control, regulate,
                change, or remove any Virtual Money or Virtual Goods as
                permitted under applicable law without any liability to you.
              </Typography>
            </Box>
          </Element>
          <Element mt={4} name="eea">
            <Typography variant="h3" mb={1}>
              15. Terms Specific to Residents of the EEA
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                15.1 Purchases and Refunds Services
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                If you live in the EEA , you have certain rights to withdraw
                from online purchases. However, please note that once you
                download Virtual Money from us, your right of withdrawal ends.
                You agree that (a) purchase of Virtual Money involves immediate
                download of such Content; and (b) you lose your right of
                withdrawal once your purchase is complete. If you live in the
                EEA, we will provide you with a VAT or Tax invoice when we are
                required to do so by law. You agree that these invoices may be
                electronic in format. We reserve the right to control, regulate,
                change, or remove any Virtual Money or Virtual Goods without any
                liability to you.
              </Typography>
            </Box>
          </Element>
          <Element mt={4} name="germany">
            <Typography variant="h3" mb={1}>
              16. Terms Specific to Residents of Germany
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                16.1 Limitation of Liability
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                In the event of intentional or gross negligence, including by
                its representatives and vicarious agents ( Erfüllungsgehilfen ),
                either Party shall be liable according to statutory provisions.
                The same shall apply in the event of culpably caused damages
                resulting from an injury to life, body or health, in the event
                of damages resulting from a violation of a guarantee as to
                quality ( Beschaffenheitsgarantie ), as well as in the event of
                defaults concealed fraudulently (a rglistig verschwiegene Mängel
                ).
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                In the event of damages to property and financial damages (
                Sach- und Vermögensschäden ) caused by slight negligence of
                either Party, its representatives or vicarious agents, such
                Party shall be liable only in the event of a violation of a
                contractual core duty ( wesentliche Vertragspflicht ), however
                limited to the amount of the damage which was foreseeable at the
                time of conclusion of the contract and typical taking into
                account the nature of the contract ( vorhersehbarer und
                vertragstypischer Schaden ). Contractual core duties are such
                duties whose accomplishment enables proper fulfillment of an
                agreement and whose observance the contracting parties may and
                do regularly rely on.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Insofar as statutory limitations of liability acc. to Sec. 521,
                599 German Civil Code apply to the provision of services free of
                charge, they remain unaffected by the aforementioned provisions.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Liability based on the German Product Liability Act shall remain
                unaffected.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Any further liability of either Party other than set out above
                shall be excluded.
              </Typography>
            </Box>
          </Element>
          <Element mt={4}>
            <Typography variant="h3" mb={1}>
              17. Account suspension or Termination
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="h4"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                17.1 Account Suspension or Termination waiver
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                You agree to waive and set aside your respective rights and
                obligations under any applicable law in the event of any
                termination of this Cofield Terms of Service (e.g., suspension
                or termination of access) to the extent that such law requires
                any judicial pronouncement for the termination of this Cofield
                Terms of Service.
              </Typography>
            </Box>
          </Element>
          <Element mt={4}>
            <Typography variant="h3" mb={1}>
              18. Language
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                This Terms of Service is made in English Language only. Cofield
                shall choose to generate and /or release Terms of use in other
                languages. Texts are equally original, In case of any
                inconsistency or different interpretation between both texts,
                the English text shall prevail, and the other interpreted
                language text shall be deemed to be automatically amended to
                conform with and to make it consistent with the relevant English
                text.
              </Typography>
            </Box>
          </Element>
        </Box>
      </Container>
    </Fragment>
  );
};

export default TermsAndConditions;
