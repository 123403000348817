import React, { Fragment } from "react";
import { Box, Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
// import { Link } from "react-router-dom";
import { Link as ScrollLink, Element, Link } from "react-scroll";

const PrivacyPolicy = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Privacy policy | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <Container maxWidth="lg">
        <Box sx={{ py: 5 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Typography variant="h1" color="#000000">
              Privacy policy
            </Typography>
            <Typography variant="body" color="#000000">
              Affective as of: 1 st July 2024
            </Typography>
          </Box>
          <Typography variant="h3" mt={4}>
            Table of Content
          </Typography>
          <Box mt={2} mb={2}>
            {[
              {
                id: "decides-information",
                text: "1. Who decides how your information is used?",
              },
              {
                id: "information-collect",
                text: "2. The information we collect about you and how we use it",
              },
              {
                id: "share-information",
                text: "3. Who we share information with",
              },
              {
                id: "personal-data-transferred",
                text: "4. How your Personal Data is transferred",
              },
              {
                id: "personal-data-safe",
                text: "5. How we keep your Personal Data safe",
              },
              {
                id: "personal-data-retention",
                text: "6. How long we will keep your Personal Data",
              },
              { id: "choices", text: "7. Your rights and choices" },
              {
                id: "children",
                text: "8. Children",
              },
              {
                id: "privacy-policy",
                text: "9. Changes to this Privacy Policy",
              },
              { id: "third-party", text: "10. Third Parties" },
              {
                id: "residents",
                text: "11. Disclosures Specific to California Residents",
              },
              { id: "exercising-rights", text: "12. Exercising Your Rights" },
            ].map((item) => (
              <Box mb={1} key={item.id}>
                <ScrollLink
                  to={item.id}
                  smooth={true}
                  duration={500}
                  offset={-70}
                  style={{
                    textAlign: "justify",
                    color: "#000000",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {item.text}
                </ScrollLink>
              </Box>
            ))}
          </Box>
          <Box mt={0.5}>
            <Typography
              variant="body1"
              sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
            >
              We are Cofield. We provide real-world augmented reality platforms,
              designed to enable you to interact in shared worlds, seamlessly
              blended with the real world. To do that, we need information about
              you. Protecting your privacy is important to us. We only use your
              information where we have a legal basis to do so. This Privacy
              Policy will help you understand what information we collect, how
              we use it and what choices you have when you play any of our games
              or use any of our apps, use our websites, buy merchandise from us,
              or take part in live events and promotions (the " Services ").
            </Typography>
            <Typography
              variant="body1"
              sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
            >
              Our Privacy Policy is designed to give you a comprehensive
              understanding of the steps that we take to protect the personal
              information that you share with us, and we would always recommend
              that you read it in full.
            </Typography>
            <Typography
              variant="body1"
              sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
            >
              You should also read our Terms of Service which set out the
              contract between you and Cofield when you use our Services.
            </Typography>
          </Box>
          <Element mt={4} name="decides-information">
            <Typography variant="h3" mb={1}>
              1. Who decides how your information is used?
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Cofield Pty Ltd, Level 3, 14 Martin Place Sydney generally is
                the data controller responsible for making decisions about how
                we use your personal information. If you are based in the United
                Kingdom (UK), Russia or the European Economic Area (EEA), your
                data controller is also Cofield Pty Ltd.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                If you have any questions or comments on this policy, you can:
              </Typography>
              <ul
                style={{
                  marginTop: "10px",
                  paddingLeft: "20px",
                  marginBottom: "20px",
                }}
              >
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Email us at{" "}
                    <Link to={"mailto:info@cofield.au"}>info@cofield.au</Link>
                  </Typography>
                </li>
              </ul>
            </Box>
          </Element>
          <Element mt={4} name="information-collect">
            <Typography variant="h3" mb={1}>
              2. The information we collect about you and how we use it
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                This Privacy Policy covers our use of any information that can
                or could be used to identify you (" Personal Data "). It does
                not cover information which cannot be used to identify you ("
                Anonymous Data ").
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                We need to collect and use certain Personal Data to provide the
                Services to you and fulfill the promises we make to you in the
                Terms of Service:
              </Typography>
              <ul
                style={{
                  marginTop: "10px",
                  paddingLeft: "20px",
                  marginBottom: "20px",
                }}
              >
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Some of our Services require you to register with us or sign
                    up for an account before you can use them. Where this is the
                    case, you provide us with information including your age and
                    the in-app username that you choose to use on our Services.
                    We collect and use this information in order to authenticate
                    you when you register an account and use the Services, to
                    make sure you are eligible and able to receive the Services,
                    and so that you receive the correct version of the Services.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    You may be required to have an account with a supported
                    external single sign-on service in order to use some of our
                    Services. Where this is the case, the Personal Data we
                    collect also depends on which external accounts you choose
                    to use, their privacy policy, and what your privacy settings
                    with those services allow us to see when you use their
                    services to access Cofield Services.
                  </Typography>
                  <ul
                    style={{
                      marginTop: "10px",
                      paddingLeft: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <li>
                      <Typography
                        variant="body1"
                        sx={{ textAlign: "justify", color: "#000000" }}
                      >
                        If you choose to link your Google account to the
                        Services, we will collect your Google email address and
                        an authentication token provided by Google.
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body1"
                        sx={{ textAlign: "justify", color: "#000000" }}
                      >
                        If you choose to link your Apple account to the
                        Services, we will collect your email address on file
                        with your Apple ID account or a private relayed email
                        address if you use the Hide My Email option provided by
                        Apple.
                      </Typography>
                    </li>
                  </ul>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Depending on the particular Service you sign up for, we may
                    support other external single sign-on services and collect
                    additional Personal Data from them. Some external providers
                    may notify you that they make additional information, such
                    as your public profile, available to us when you use their
                    single sign-on services. We do not collect that information
                    from them.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    If you elect to use your Cofield Profile in connection with
                    a third-party developer service (a “ Connected Service “),
                    we may collect the Personal Data outlined above.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    We collect and use your device location information as you
                    use our Services (and, if you elect to turn on background
                    location tracking for participating Services, while you are
                    not directly interacting with these Services), including how
                    you move around and events that occur during in app activity
                    or gameplay. Our Services include location based games whose
                    core feature is to provide an experience tied to your real
                    world location, so we need to know where you are to operate
                    these apps and games for you, and to plan the location of
                    in-game resources (for example Figs within Figrun app). We
                    identify your location using a variety of technologies,
                    including GPS, the WiFi points you are accessing the Service
                    through and mobile/cell tower triangulation.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    We also collect and use your in-game or in-app actions and
                    achievements, as well as certain information about your
                    mobile device (including device identifiers, device OS,
                    model, configuration, settings, and information about
                    third-party applications or software installed on your
                    device), to operate the Services for you and to personalize
                    your gameplay and user experience. We will also generate an
                    internal account ID when you use certain Services to
                    associate you with an account.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    We also use the information above to show in-game sponsored
                    locations that are in your vicinity as part of the gameplay
                    experience.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    We further use the information above in order to provide
                    technical and customer support to you.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    You also give us Personal Data when you make a purchase
                    through us, subscribe to our publications, register for a
                    live event, enter a competition, promotion, sweepstakes or
                    survey or communicate with us. Depending on which of these
                    Services you use, that may include your name, mailing
                    address, phone number, country of residency, age, and email
                    address. We use that information to fulfill those Services
                    to you and to provide related customer support to you.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Our games and apps include innovative features which allow
                    you and other players to view and interact with the same
                    virtual objects at the same time in a shared physical space.
                    They also allow you to leave persistent virtual objects at
                    real world locations, so other players can see them even
                    when you are not there, and so you can see them when you
                    return. In order to provide you with these AR experiences,
                    Cofield needs to collect geospatial data and images from
                    your device camera and sensors during your AR experiences
                    where applicable within app or gameplay. Please see our Help
                    Center for more information on AR experiences and Privacy.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Using your IP address, browser type, operating system, the
                    web page you were visiting before accessing our Services,
                    the pages of our Services which you browsed or the features
                    you used, and the time spent on those pages or features, the
                    links on our Services that you click on, device and
                    advertising identifiers, age, gender, as well as actions you
                    take during gameplay, your in-app user settings, preferences
                    (including avatar characteristics), and your in-app
                    purchases to learn about our users, and understand who is
                    using our Services and how.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Using your contact information, namely your email address in
                    order to communicate with you to provide technical and
                    customer support.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Using your internal account ID and in-game username in order
                    to attribute to your account any user content (such as local
                    points of interest) that you elect to submit to Cofield
                    through the Services. You can choose to opt out of having
                    your in-game username attributed on your Wayfarer
                    submissions across Cofield products by going to the Cofield
                    Wayfarer settings.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Using your email address and device information in order to
                    share updates and news with you either within the apps or by
                    email. You can unsubscribe from these at any time in your
                    device settings or in-app settings.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Analyzing your in-game actions and achievements to show you
                    rewards, promotions, or other offers about our Services that
                    are tailored to your personal user experience. You can
                    choose to opt out of this and certain other processing of
                    your Personal Data by following the steps described in
                    Section 7 (“Your rights and choices”).
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    To personalize the ads you see in our apps to make them more
                    relevant to you and to show sponsored gifts based on where
                    you are containing messages and/or offers from our
                    advertisers relevant to your vicinity on the game map. You
                    can choose to opt out of receiving sponsored gifts by
                    visiting the in-app settings. You can choose to opt out of
                    personalized advertisements by visiting the settings on your
                    device and turning off “Allow Apps to Request to Track” or
                    activating “Limit Ad Tracking” on Apple devices, or
                    activating “Opt-out of Interest Based Ads” on Android
                    devices.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    We collect content you provide such as images, text, and/or
                    video to provide social features within our games and apps
                    so you can interact and play with other players, including
                    storing your communications, content, and other information
                    shared with those players, finding your friends and being
                    found by them, including through imported contacts, and
                    sharing your gameplay or in-app experience and achievements
                    with your friends or other players. You can visit your
                    Cofield Profile or in-app settings for opt-out options. We
                    may monitor and moderate content you provide to our Services
                    to protect Cofield, or to protect the rights and safety of
                    others.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    To improve the features that we offer you through the
                    Services, or to provide you with new or additional features
                    for our Services.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    To organize and run live events based on or featuring our
                    games. Note that when you participate in live events your
                    in-game actions and achievements, in-game username and your
                    avatar, team, and other components of your in-game profile
                    will be visible to other event participants and to the
                    public (for example on leaderboards displayed at the event
                    and online).
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Using your in-app actions and achievements, as well as
                    certain information about your mobile device (including
                    device identifiers, device OS, model, configuration,
                    settings, and information about third-party applications or
                    software installed on your device), to carry out anti-fraud
                    and anti-cheating measures against behaviours prohibited
                    under our Terms of Service, to prevent abuse, and to provide
                    a fair gaming experience to all players.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    To make legal or regulatory disclosures and to establish,
                    exercise, or defend legal claims.
                  </Typography>
                </li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  We will only use your Personal Data to do the following if we
                  have your consent:
                </Typography>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    If you elect to turn on background activity tracking in our
                    Services (for example Adventure Sync in Figrun app) we will
                    collect your Personal Data as you use the Services as well
                    as in the background when you do not have the Services open
                    on your device. This includes your device location and your
                    fitness activity data (locations, cities, steps or distance
                    travelled). We use background activity tracking to provide
                    you certain functionalities in the Services such as in-game
                    items and rewards tied to your fitness activity level,
                    location, and alerts for nearby gameplay events. You can
                    change your mind and turn off background activity tracking
                    at any time in your device settings or in-app settings.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    If you elect to add information about Cofield live or
                    in-game events to your mobile calendar, we will, with your
                    permission, access your device’s calendar and write in these
                    events. We do not access or collect any other information
                    from your calendar. You can change your mind and disable
                    access to your calendar at any time in your device settings.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    If you elect to help Cofield in its efforts to develop new
                    Augmented Reality (AR) mapping technology, you have the
                    option, in participating games, to opt in to film public
                    spaces around points of interest and send us your video
                    recordings, along with associated device geospatial
                    information. We do not collect audio on these recordings. We
                    will anonymize this information through various means,
                    including blurring, and use it to build a 3D understanding
                    of real-world places, with the goal of offering new types of
                    AR experiences to our users. You can change your mind at any
                    time by disabling this feature in your in-app settings.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    If you elect to connect your game account with an authorized
                    external device, we request Bluetooth permissions from your
                    mobile device in order to connect it with the external
                    device and enable associated game features. You may change
                    your mind at any time by disabling our access to Bluetooth
                    in your device settings or by disconnecting the external
                    device from the game.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    If you elect to import your device’s address book contacts
                    to find out which of your contacts uses our Services and to
                    send them Friend requests, or invite them to join you in our
                    games, apps or other aspects of our Services, we will
                    periodically sync your contacts’ information, including
                    phone number, and email, to our servers. You are responsible
                    for ensuring your collection and sharing with us of your
                    contacts’ information is compliant with applicable laws.
                    This may require you to obtain your contacts’ permission. We
                    may also get information about you from other Cofield users
                    importing or entering their contacts. We use and may store
                    this contact information to help you and your contacts
                    connect through our Services. You can change your mind and
                    turn off Cofield’s access to your contacts at any time in
                    your device settings.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    If you elect to upload content, such as photos or videos
                    from your mobile device, into our Services, we will, with
                    your permission, access your device’s media storage. You can
                    change your mind and disable access to your media storage at
                    any time in your device settings.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Send you marketing materials by email or via in-app
                    notifications. You can unsubscribe from these at any time in
                    your device settings or in-app settings.
                  </Typography>
                </li>
              </ul>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                We also rely on your consent where we use cookies or similar
                technologies. Please see our Cookie Policy for more information
                about how we use them. Insofar as cookies collect Personal Data,
                we will process it only based on your explicit consent, in
                anonymized form.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                While you may disable the usage of cookies through your browser
                settings, Cofield currently does not respond to a “Do Not Track”
                signal in the HTTP header from your browser or mobile
                application due to the lack of industry standard on how to
                interpret that signal.
              </Typography>
            </Box>
          </Element>
          <Element mt={4} name="share-information">
            <Typography variant="h3" mb={1}>
              3. Who we share information with
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                We will not share any Personal Data that we have collected from
                or about you except as described below:
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Information Shared with Our Service Providers . We engage
                external Service Providers to work with us to administer and
                provide the Services. As part of that they will process your
                Personal Data on our behalf. These external Service Providers
                have access to your Personal Data only for the purpose of
                performing services on our behalf, in compliance with this
                Privacy Policy, and we make sure each one is contractually
                obliged not to disclose or use your Personal Data for any other
                purpose. The Service Providers we use help us to:
              </Typography>
              <ul
                style={{
                  marginTop: "10px",
                  paddingLeft: "20px",
                  marginBottom: "20px",
                }}
              >
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    run, operate, and maintain our Services through third-party
                    platform and software tools;
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    perform content moderation and crash analytics;
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    run email and mobile messaging campaigns;
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    perform game, app, and marketing analytics;
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    administer live events, competitions, sweepstakes and
                    promotions, including registering players, managing check-in
                    and attendance, verifying eligibility and prize fulfillment;
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    provide payment attribution;
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    provide pay-out payment transactions
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    provide technical and customer support; and
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    process payments for live events ticketing or other
                    purchases.
                  </Typography>
                </li>
              </ul>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Some external service providers may also collect information
                directly from you (for example, a payment processor may request
                your billing and financial information) as a Third-Party Service
                in accordance with their own privacy policy. Such external
                Third-Party Service does not share your financial information,
                like credit card number, with Cofield, but it may share limited
                information with us related to your purchase, like your zip or
                postal code.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                <b>Information Shared with Other Players or Users.</b> When you
                use the Services, and in particular when you play our games, use
                social features within those games (including cross-game
                features), or take part in live events, we will share certain
                Personal Data with other players or users. This Personal Data
                includes your in-game or in-app profile (such as your username,
                your profile picture, your online status, and your team), your
                in-game or in-app actions and achievements, real-world locations
                associated with your gameplay, or which gameplay resources you
                interacted with when playing the game (for example Fig Packs
                within Figrun,, and your in-game messages and related content
                you share with other players or users. Additionally, certain
                games or apps link to third-party sharing features on your
                device that you can use to disclose Personal Data to others
                outside the Services; Cofield does not control and is not
                responsible for the practices of these third-party sharing
                features.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                <b>Information Shared Publicly :</b> When you use the Services,
                and in particular when you play our games, use competitive
                features, or participate in our forums, the following
                information about you may be shared on web pages accessible to
                the public and therefore become publicly available: your
                username, in-game or in-app profile, achievements, and public
                messages.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                <b>Information Shared with Third Parties .</b> We share
                Anonymous Data with third parties for industry and market
                analysis. We may share Personal Data with our third-party
                publishing partners for their direct marketing purposes only if
                we have your express permission. We do not share Personal Data
                with any other third parties for their direct marketing
                purposes.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                <b>
                  Information Disclosed for Our Protection and the Protection of
                  Others .
                </b>{" "}
                We cooperate with government and law enforcement officials or
                private parties to enforce and comply with the law. We only
                share information about you to government or law enforcement
                officials or private parties when we reasonably believe
                necessary or appropriate: (a) to respond to claims, legal
                process (including subpoenas and warrants); (b) to protect our
                property, rights, and safety and the property, rights, and
                safety of a third party or the public in general; or (c) to
                investigate and stop any activity that we consider illegal,
                unethical, or legally actionable.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                <b>
                  Information Disclosed in Connection with Business Transactions
                  .
                </b>
                Information that we collect from our users, including Personal
                Data, is a business asset. If we are acquired by a third party
                as a result of a transaction such as a merger, acquisition, or
                asset sale or if our assets are acquired by a third party in the
                event we go out of business or enter bankruptcy, some or all of
                our assets, including your Personal Data, will be disclosed or
                transferred to a third-party acquirer in connection with the
                transaction.
              </Typography>
            </Box>
          </Element>
          <Element mt={4} name="personal-data-transferred">
            <Typography variant="h3" mb={1}>
              4. How your Personal Data is transferred
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                Cofield operates its Services across the globe. Your Personal
                Data will likely be transferred and stored in a country outside
                of your home country, for the purposes outlined in this Privacy
                Policy to the extent permitted by applicable laws. The data
                protection laws in these countries may not be the same as in
                your home country.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                If we transfer your Personal Data from the UK or the EEA to
                other countries, including the USA, we ensure that a similar
                degree of protection is provided to your Personal Data as within
                the UK or the EEA, as applicable, by ensuring that at least one
                of the following safeguards is implemented:
              </Typography>
              <ul
                style={{
                  marginTop: "10px",
                  paddingLeft: "20px",
                  marginBottom: "20px",
                }}
              >
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    The country that your Personal Data is transferred to is a
                    country that the European Commission (or the UK Government,
                    if you are based in the UK) has deemed to provide an
                    adequate level of protection for Personal Data as the UK or
                    the EEA, as applicable.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    We use specific contracts approved by the European
                    Commission (or the UK Information Commissioner’s Office, if
                    you are based in the UK), which give Personal Data the same
                    protection as it has in the UK or the EEA, as applicable,
                    when we engage with service providers.
                  </Typography>
                </li>
              </ul>
            </Box>
          </Element>
          <Element mt={4} name="personal-data-safe">
            <Typography variant="h3" mb={1}>
              5. How we keep your Personal Data safe
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                We have appropriate legal, organizational and technical security
                measures in place to prevent your Personal Data from being
                accidentally lost, used or accessed in an unauthorized way,
                improperly altered or disclosed. We also limit access to your
                Personal Data to employees, agents, contractors, and other third
                parties who have a business need to know. They will only process
                your Personal Data on our instructions and they are subject to a
                duty of confidentiality. We have put in place procedures to deal
                with any suspected Personal Data breach and will notify you and
                any applicable regulator of a breach where we are legally
                required to do so.
              </Typography>
            </Box>
          </Element>
          <Element mt={4} name="personal-data-retention">
            <Typography variant="h3" mb={1}>
              6. How long we will keep your Personal Data
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                We keep your Personal Data for as long as we need to provide the
                Services to you and fulfill the purposes set out in this Privacy
                Policy. This is also the case for anyone that we share your
                information with and who carry out services on our behalf.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                When we no longer need to use your Personal Data and there is no
                need for us to keep it to comply with our legal or regulatory
                obligations, we will either remove it from our systems or
                anonymize it so that it can no longer be associated with you.
                When removing Personal Data, we will take commercially
                reasonable and technically feasible measures to make said
                Personal Data irrecoverable or irreproducible.
              </Typography>
            </Box>
          </Element>
          <Element mt={4} name="choices">
            <Typography variant="h3" mb={1}>
              7. Your rights and choices
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                You have certain rights in relation to your Personal Data. This
                means you can:
              </Typography>
              <ul
                style={{
                  marginTop: "10px",
                  paddingLeft: "20px",
                  marginBottom: "20px",
                }}
              >
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Request access to the Personal Data we hold on you by
                    emailing info@cofield.au
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Delete or correct your Personal Data. The easiest way to
                    update your account information is via your in-app settings.
                    You can also submit a customer support request through our
                    support website or email us
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Ask us to stop processing your Personal Data, including for
                    direct marketing and promotional purposes such as tailored
                    rewards, promotions, and other offers, by emailing us. But
                    be aware that sometimes we need to use your Personal Data in
                    order to provide the Services to you.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Have your Personal Data transferred to another organization
                    (where it is technically feasible).
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Complain to a regulator. We'd appreciate the chance to deal
                    with your concerns directly so we'd prefer you to contact us
                    first. However, if you're based in the UK or the EEA and
                    believe that we have not complied with data protection laws,
                    you can complain to your local supervisory authority.
                  </Typography>
                </li>
              </ul>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                For Connected Services, you can request access to or delete your
                data through your Cofield Profile settings, available at our
                website
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                The law provides exceptions to these rights in certain
                circumstances. Where you cannot exercise one of these rights due
                to such an exception, we will explain to you why.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                We offer you choices regarding the collection, use, and sharing
                of your Personal Data and we’ll respect the choices you make.
                Please note that if you decide not to provide us with the
                Personal Data that we request, you may not be able to access all
                of the features of the Services.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                After you contact us, you may receive an email in order to
                verify your request. We aim to provide the information or
                complete the outcome you request within 30 days, or such shorter
                time period as provided by laws of your jurisdiction.
              </Typography>
            </Box>
          </Element>
          <Element mt={4} name="children">
            <Typography variant="h3" mb={1}>
              8. Children
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Unless stated otherwise for a particular Service, children are
                not allowed to use the Services, and we do not knowingly collect
                Personal Data from them. We define “children” as follows:
              </Typography>
              <ul
                style={{
                  marginTop: "10px",
                  paddingLeft: "20px",
                  marginBottom: "20px",
                }}
              >
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Residents of the UK: anyone under 13 years old.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Residents of the EEA: anyone under 16 years old, or the age
                    needed to consent to the processing of personal data in your
                    country of residence.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Residents of the Republic of Korea, anyone under 14 years
                    old.
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", color: "#000000" }}
                  >
                    Residents of other regions: anyone under 13 years old or the
                    age needed to consent to the processing of personal data in
                    that region.
                  </Typography>
                </li>
              </ul>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Figrun Contest Official Rules
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                Cofield Game and App Resources contains information on the age
                requirement for each of our Apps.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 2 }}
              >
                For Services that do permit Child participation, parents or
                legal guardians ("Parents") must provide verified consent.
                Verifiable parental consent is required for the collection, use,
                or disclosure of a child's Personal Data. Cofield will not
                collect, use, or disclose any Personal Data from a child if
                their parent or guardian does not provide such consent. We may
                ask a Child to provide a Parent’s email address in order to
                request this consent, and the Parent’s email address will be
                deleted if consent is not provided within a reasonable time.
              </Typography>
            </Box>
          </Element>
          <Element mt={4} name="privacy-policy">
            <Typography variant="h3" mb={1}>
              9. Changes to this Privacy Policy
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                Any Personal Data that we collect is covered by the Privacy
                Policy in effect at the time such information is collected. We
                may make changes to this Privacy Policy from time to time. You
                will be given reasonable notice of any material change.
              </Typography>
            </Box>
          </Element>
          <Element mt={4} name="third-party">
            <Typography variant="h3" mb={1}>
              10. Third Parties
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                In using our Services you may access links to websites and
                services that are owned or operated by third parties (each, a “
                Third-Party Service “). Any information that you provide on or
                to a Third-Party Service or that is collected by a Third-Party
                Service is provided directly to the owner or operator of the
                Third-Party Service and is subject to the owner’s or operator’s
                privacy policy. We’re not responsible for the content, privacy,
                or security practices and policies of any Third-Party Service.
                To protect your information we recommend that you carefully
                review the privacy policies of all Third-Party Services that you
                access.
              </Typography>
            </Box>
          </Element>
          <Element mt={4} name="residents">
            <Typography variant="h3" mb={1}>
              11. Disclosures Specific to California Residents
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                California law requires that we make certain disclosures about
                your data. The following disclosures are made pursuant to the
                California Consumer Privacy Act of 2018 as amended by the
                California Privacy Rights Act of 2020 (“CCPA”).
              </Typography>
              <Box mb={2}>
                <Typography
                  variant="h4"
                  sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
                >
                  Collection and Use of Personal Data
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
                >
                  In the preceding 12 months, we have collected the following
                  categories of Personal Data: identifiers, commercial
                  information, Internet or other electronic network activity
                  information, geolocation data, and visual information. To
                  learn more about the specific information we collect, the
                  sources and purposes of such collection, please see Section 2
                  (“The information we collect about you and how we use it”).
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography
                  variant="h4"
                  sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
                >
                  Sale or Sharing of Personal Data
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
                >
                  We do not sell or share your Personal Data as those terms are
                  defined under the CCPA. Cofield has no actual knowledge of
                  selling or sharing the personal information of minors under 16
                  years of age.
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography
                  variant="h4"
                  sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
                >
                  Disclosure of Personal Data for a Business Purpose
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
                >
                  In the preceding 12 months, we have disclosed for a business
                  purpose the following categories of Personal Data:
                  identifiers, commercial information, Internet or other
                  electronic network activity information, geolocation data, and
                  visual information. To learn more about the disclosure of your
                  Personal Data, please see Section 3 (“With whom we share
                  information”).
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
                >
                  Your CCPA Rights
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography
                  variant="h4"
                  sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
                >
                  Right to Know and Access Personal Information
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
                >
                  You have the right to request the categories and specific
                  pieces of personal information we have collected about you.
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography
                  variant="h4"
                  sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
                >
                  Right to Delete Personal Information
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
                >
                  You have the right to request the deletion of your personal
                  information, subject to certain exceptions.
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography
                  variant="h4"
                  sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
                >
                  Right to Correct Inaccurate Personal Information
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
                >
                  You have the right to request the correction of any inaccurate
                  personal information that we maintain about you.
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography
                  variant="h4"
                  sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
                >
                  Right to Limit Use and Disclosure of Sensitive Personal
                  Information
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
                >
                  We do not collect or process sensitive personal information
                  for the purpose of inferring characteristics about you. We
                  also do not disclose sensitive personal information for
                  purposes other than those specified in Section 7027(m) of the
                  California Privacy Rights Act regulations promulgated by the
                  California Privacy Protection Agency. Therefore, we do not
                  offer you the option to limit the use of your sensitive
                  personal information.
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography
                  variant="h4"
                  sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
                >
                  Right to Opt Out of Sale or Sharing
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
                >
                  Under California law, consumers have the right to opt out of
                  the sale or sharing of their personal information. Because we
                  do not sell or share data for this purpose, we do not offer
                  this opt out.
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography
                  variant="h4"
                  sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
                >
                  Right of Non-Discrimination
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
                >
                  You have the right to exercise the privacy rights conferred to
                  you under the CCPA without receiving discriminatory treatment.
                  We do not discriminate against you for exercising the privacy
                  rights conferred to you under the CCPA.
                </Typography>
              </Box>
            </Box>
          </Element>
          <Element mt={4} name="exercising-rights">
            <Typography variant="h3" mb={1}>
              12. Exercising Your Rights
            </Typography>
            <Box mt={0.5}>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                You may exercise these rights by reviewing Section 7 (“Your
                rights and choices”) of this Privacy Policy.
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "justify", color: "#000000", mb: 1 }}
              >
                If you authorize another person to submit a request to know,
                correct, or delete on your behalf, we may ask you to verify your
                identity or to confirm that you authorized another person to
                make such request on your behalf. Separately, such other person
                may have to submit proof that they are authorized to make a
                request on your behalf.
              </Typography>
            </Box>
          </Element>
        </Box>
      </Container>
    </Fragment>
  );
};

export default PrivacyPolicy;
